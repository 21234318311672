import type { ButtonHTMLAttributes, ReactNode } from 'react';

import ArrowRight from '@m/assets/svg/arrow-right';
import LoadingSpinnerWithBackground from '@m/components/LoadingSpinnerWithBackground';
import theme from '@m/theme/theme';

import { Content, LoadingContainer, StyledButton } from './BrandButton.styled';

export type BrandButtonProps = {
  children?: string | ReactNode;
  /** Display loading icon IF button disabled */
  displayLoading?: boolean;
  reverseColors?: boolean;
  textCenter?: boolean;
  displayArrow?: boolean;
  showGreenVariant?: boolean;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

const BLURPLE = '#7772F7';
const WHITE = '#FFF';

export default function BrandButton({
  id,
  children,
  onClick,
  disabled,
  displayLoading,
  reverseColors,
  textCenter,
  displayArrow,
  className,
  showGreenVariant,
  ...buttonProps
}: BrandButtonProps) {
  let spinnerColor: string = BLURPLE;
  let spinnerBackgroundColor: string = WHITE;
  if (reverseColors) {
    spinnerColor = theme.colors.blue2;
    spinnerBackgroundColor = theme.colors.blue4;
  }
  if (showGreenVariant) {
    spinnerColor = '#00A556';
  }

  return (
    <StyledButton
      {...buttonProps}
      id={id}
      className={className}
      disabled={disabled}
      reverseColors={reverseColors}
      textCenter={textCenter}
      removeDisabledFilter={displayLoading}
      onClick={onClick}
      greenVariant={showGreenVariant}
    >
      {disabled && displayLoading ? (
        <LoadingContainer>
          <LoadingSpinnerWithBackground
            backgroundColor={spinnerBackgroundColor}
            color={spinnerColor}
          />
        </LoadingContainer>
      ) : (
        <>
          <Content>{children}</Content>
          {displayArrow && (
            <ArrowRight
              css={`
                position: absolute;
                right: 20px;
              `}
              fill={reverseColors ? 'blue6' : '#fff'}
            />
          )}
        </>
      )}
    </StyledButton>
  );
}
